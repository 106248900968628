<template>
	<b-card
		class="h-100"
		no-body
		@mouseover="$event.currentTarget.classList.add('shadow')"
		@mouseleave="$event.currentTarget.classList.remove('shadow')"
	>
		<ribbon
			v-if="variation.mrp > variation.price"
			class="text-uppercase"
			:text="`${
				merchant.settings.app.appearance && merchant.settings.app.appearance.discount_banner_type === 'percentage' ?
					`${ ((variation.mrp - variation.price) * 100 / variation.mrp ).toFixed(2)}%` :
					toCurrency(variation.mrp - variation.price)
			} ${$t('off')}`"
			style="top: -1px"
		/>
		<div
			class="img-wrap"
			:style="{
				height:'150px',
				width:'100%',
			}"
			@click="$root.$emit('showImages', variation.images)"
		>
			<b-img-lazy
				v-bind="{
					blank: true,
					blankColor: '#ccc',
				}"
				:src="variation.images[0] || '/images/placeholder.svg'"
			/>
			<favorite v-if="controls" :variation="variation" style="top: 10px; right: 10px" />
			<b-link v-if="variation.images.length" class="text-capitalize text-overlay">
				<b-icon-images class="mr-1" />
				{{ $t('quick view') }}
			</b-link>
		</div>
		<b-card-body class="cursor-pointer d-flex flex-column p-3" @click="$router.push(`/items/${variation.slug}`)">
			<b-link class="card-link py-0 my-0">
				<p>
					{{ variation.item_attributes.length ? item.name : variation.name }}
					<span
						v-if="variation.alt_lang"
					>
						/ {{ variation.alt_lang }}
					</span>
				</p>
			</b-link>
			<div
				v-if="controls && variation.item_attributes.length"
				class="d-flex justify-content-between align-items-center border p-1 mb-1"
				@click.stop="showAttributes(item)"
			>
				<small class="mt-0 mb-0">
					{{ item.selected_attributes.map(a => a.label).join(', ') }}
				</small>
				<b-icon icon="chevron-down" class="my-auto float-right" />
			</div>
			<div class="mt-auto">
				<p class="mb-0">
					<span>
						{{ toCurrency((variation.original_price || variation.price) / (variation.unit_measure_type === 'weight' && merchant.settings.general.quantity_increment_value === '0.5' ? 2 : 1)) }}
						<span v-if="variation.unit_measure_type === 'weight' && merchant.settings.general.quantity_increment_value">
							{{ `/ ${merchant.settings.general.quantity_increment_value} kg` }}
						</span>
					</span>
					<del v-if="variation.mrp" class="text-muted">
						<small>
							{{ toCurrency(variation.mrp) }}
						</small>
					</del>
					<b-link
						v-if="variation.combos.length"
						v-b-tooltip.hover
						:title="variation.combos[0].items.map(i => i.name).join(', ')"
						class="card-link text-capitalize float-right"
						@click.prevent.stop
					>
						{{ variation.combos[0].name }}
					</b-link>
				</p>
				<div class="text-center">
					<stepper v-if="controls" :key="`stepper-${selectedStore ? selectedStore.id : null}-${variation.id}`" :item="item" />
				</div>
				<div class="text-center">
					<small
						v-if="variation.modifiers && variation.modifiers.length"
						class="d-block text-muted text-capitalize"
					>{{ $t('customisable') }}
					</small>
				</div>
			</div>
		</b-card-body>
	</b-card>
</template>

<script>
	import Ribbon from '@/components/ribbon'
	import Favorite from '@/components/favorite'

	export default {
		components: {
			Ribbon,
			Favorite
		},
		props: {
			item: {
				type: Object,
				required: true
			},
			controls: {
				type: Boolean,
				default: true
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			selectedStore () {
				return this.$store.state.selectedStore
			},
			selectedItem: {
				get () {
					return this.$store.state.selectedItem
				},
				set (value) {
					this.$store.commit('setState', { key: 'selectedItem', value })
				}
			},
			variation () {
				return this.item && this.item.variations ? this.item.variations[this.item.variation_index] : this.item
			}
		},
		methods: {
			showAttributes (item) {
				this.$store.commit('setState', { key: 'selectedItem', value: item })
				this.$root.$emit('show-attributes')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.card-link {
		min-height: 48px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
	}
</style>
